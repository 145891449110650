import React, { useState } from "react";
import LayoutWrapper from "../../component/LayoutWrapper/Index";
import Bio from "../../component/TeamBio";

const TeamBio = (props: any) => {
  const [siteTitle, setSiteTitle] = useState("");
  const [siteDesc, setSiteDesc] = useState("");
  const pageData = {
    id: "ryan-hunter",
    titleTag: "Ryan Hunter | Experienced Real Estate Agent | Selby Team",
    descTag:
      "Meet Ryan Hunter, Client Care Coordinator for The Selby Team and experienced realtor.",
  };
  return (
    <LayoutWrapper
      title={pageData.titleTag}
      desc={pageData.descTag}
      headerLogo="../../images/Selby_logo_v2.svg"
      headerId="header_v2"
    >
      <Bio id={pageData.id} setSiteTitle={setSiteTitle} setSiteDesc={setSiteDesc} />
    </LayoutWrapper>
  );
};

export default TeamBio;
